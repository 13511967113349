<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-08 11:34:18
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-21 15:11:36
-->
<template>
  <div class="equipment">
    <div class="main">
      <!-- left -->
      <div class="navList" :style="foldStyle">
        <leftNav :treeData="treeData" @node="activeNodeF" @emitF="emitLeft" />
      </div>
      <!-- left end -->
      <!-- right -->
      <div class="details">
        <div class="on_off">
          <i
            class="iconfont"
            :class="foldFlag ? 'ql-icondanchunew' : 'ql-iconshouqinew'"
            @click="foldF"
          ></i>
        </div>
        <div class="listBox">
          <!-- 头部 -->
          <div class="Head">
            <div class="head">
              <p>{{ node.name }}</p>
            </div>

            <div>
              <el-radio-group size="small" v-model="deviceType">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button label="0">自有</el-radio-button>
                <el-radio-button label="1">关联</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <el-input
              prefix-icon="el-icon-search"
              style="width: 450px"
              placeholder="请输入内容"
              v-model="deviceName"
              clearable
              size="small"
              @input="inputF"
            >
            </el-input>
            <div class="option">
              <div>
                <el-button
                  size="small"
                  plain
                  @click="addnewdevice"
                  class="newadd"
                >
                  <span>导入设备</span>
                  <i class="el-icon-plus jiahao"></i>
                </el-button>
              </div>
            </div>
          </div>

          <card
            @path="pathDeviceF"
            :list="deviceArr"
            @operation="operationF"
            :deviceInfoObj="deviceInfoObj"
          />
          <!-- 分页 -->
          <div class="page">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="deviceArr.length"
            >
            </el-pagination>
          </div>
          <!-- 分页 end -->
        </div>
      </div>
      <!-- right end -->
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="425px"
      @close="dialogclose()"
      :destroy-on-close="true"
    >
      <div
        class="dialogtitle"
        slot="title"
        v-text="typeObj[type] + '分组'"
      ></div>
      <p style="margin-bottom: 10px">分组名称</p>
      <div>
        <el-input
          size="small"
          v-model="groupnameVal"
          :disabled="type == 'move'"
        ></el-input>
      </div>

      <p v-if="type == 'move'" class="selectdev" style="margin: 10px 0">
        当前位置
      </p>
      <div v-if="type == 'move'">
        <el-input size="small" :disabled="true" v-model="groupSplit"></el-input>
      </div>
      <!-- 选择分组 -->
      <div v-if="type == 'move'">
        <p class="selectdev" style="margin: 10px 0">选择分组</p>
        <div
          :class="selectDepartmentS ? 'select on' : 'select off'"
          @click.stop="selectDepartmentS = !selectDepartmentS"
        >
          <el-input
            v-model="fatherDepartment"
            placeholder="请输入内容"
            :readonly="true"
            size="small"
          >
            <i
              slot="suffix"
              style="transform-origin: center"
              :style="
                selectDepartmentS
                  ? 'cursor:pointer;padding-right:3px;transform: rotate(180deg)'
                  : 'cursor:pointer;padding-right:3px;transform: rotate(0)'
              "
              class="el-input__icon el-icon-caret-bottom"
            ></i>
          </el-input>
          <transition name="el-fade-in-linear">
            <div class="ops" @click.stop v-show="selectDepartmentS">
              <el-scrollbar style="height: 100%">
                <el-tree
                  :filter-node-method="filterNode"
                  auto-expand-parent
                  :data="treeData"
                  :default-expand-all="true"
                  show-checkbox
                  node-key="uId"
                  ref="MoveTreeList"
                  highlight-current
                  :check-strictly="true"
                  @check="checkChangeF"
                  :props="{ children: 'childGroup', label: 'name' }"
                  :check-on-click-node="true"
                ></el-tree>
              </el-scrollbar>
            </div>
          </transition>
        </div>
      </div>
      <!-- 选择分组 end -->
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="dialogVisible = false"
          size="small"
          class="cancelcancel"
          >取 消</el-button
        >
        <el-button type="primary" @click="submint()" size="small"
          >完 成</el-button
        >
      </span>
    </el-dialog>
    <!-- 弹窗end -->
    <!-- isShowAddDevice -->
    <!-- 添加设备 -->
    <importDevice v-if="isShowAddDevice" @close="closeAdd" :node="node" />
    <!-- 添加设备 end -->
    <!-- 移动设备弹窗 -->
    <moveC
      :show="isMove"
      :info="moveInfo"
      @close="closeF"
      :treeData="treeData"
      :groupSplit="groupSplit"
    />
    <!-- 移动设备弹窗 end -->
  </div>
</template>

<script>
import leftNav from "./private/EquipmentLeftComponents"; //左侧目录
import card from "./private/EquipmentContentCardComponents"; //设备列表卡片样式
import moveC from "./private/EquipmentMoveComponents"; //设备移动
import importDevice from "./private/EquipmentImportDeviceComponents";

import { getDeviceNameByHoneywell, getDeviceInfo } from "@/api/basics.js";
import {
  tree,
  deletegroup,
  update,
  treeDevice,
  deleteDevice,
  addHoneyDeviceGroup,
  updateGroupToGroup,
} from "@/api/equipment.js";
export default {
  name: "equipment",
  components: { leftNav, card, importDevice, moveC },
  data() {
    return {
      isMove: false, //move弹窗状态
      moveInfo: {},
      selectDepartmentS: false,
      deviceType: "",
      deviceName: "",
      isShowAddDevice: false,
      pageSize: 10,
      currentPage: 1,
      showType: 1,
      editId: 0,
      typeObj: {
        add: "添加",
        edit: "编辑",
        move: "移动",
      },
      dialogVisible: false,
      treeData: [],
      foldFlag: false, //折叠标识
      foldStyle: {
        width: "248px",
        padding: "16px",
      },
      fatherDepartment: "",
      node: {},
      deviceArr: [],
      groupnameVal: "",
      deviceInfoObj: {},
      InfoData: {},
      select: {}, //编辑时选择的分组
      groupSplit: "",
      type: "",
      timeF: null,
    };
  },

  computed: {},
  //监听执行
  watch: {
    dialogVisible() {
      if (!this.dialogVisible) {
        this.groupnameVal = "";
      }
    },
    deviceType() {
      this.currentPage = 1;
      this.getDeviceF();
    },
  },
  mounted() {
    this.init();
    this.getTreeDeviceF();
  },
  methods: {
    inputF() {
      clearTimeout(this.timeF);
      this.timeF = setTimeout(() => {
        this.currentPage = 1;
        this.getDeviceF();
      }, 1000);
    },
    filterNode(val, data) {
      // 过滤
      return data.split.indexOf(this.groupSplit) !== 0;
    },
    checkChangeF(data) {
      this.select = data;
      this.fatherDepartment = this.select.split + "/" + this.groupnameVal;
      this.$refs.MoveTreeList.setCheckedKeys([data.uId], false);
      this.selectDepartmentS = false;
    },
    init() {
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      getDeviceNameByHoneywell({
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        applyId: WORK_APPLY.id, //物联应用id
      }).then((res) => {
        if (this.ApiReply(res)) {
          this.deviceInfoObj = {
            ...res.data.data,
          };
        }
      });
    },
    pathDeviceF(data) {
      // 点击的设备
      console.log(data);
      this.toPath(
        "/equipment/devience?deviceId=" +
          data.hDeviceId +
          "&qDeviceId=" +
          data.qDeviceId +
          "&deviceName=" +
          data.deviceName
      );
    },
    closeAdd(type) {
      if (type == 1) {
        // 更新设备列表
        this.init();
        this.getDeviceF();
      }
      this.isShowAddDevice = false;
    },
    operationF(type, data) {
      // 操作方法
      if (type == "delete") {
        this.$confirm("此操作将永久解绑该设备, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
            deleteDevice({
              enterpriseId: WORK_APPLY.enterpriseId, //企业id
              applyId: WORK_APPLY.id, //物联应用id
              id: data.id,
            }).then((res) => {
              if (this.ApiReply(res)) {
                this.$message({
                  type: "success",
                  message: "已解绑!",
                });
                // 更新设备列表
                this.getDeviceF();
              }
            });
          })
          .catch(() => {
            console.log("error");
          });
      }
      // 移动
      if (type == "move") {
        this.moveInfo = data;
        this.isMove = true;
      }
    },
    closeF(type) {
      if (type == 1) {
        this.getTreeDeviceF();
      }
      this.isMove = false;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDeviceF();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDeviceF();
    },
    addnewdevice() {
      // 导入设备
      this.isShowAddDevice = true;
    },
    command(data) {
      this.showType = data;
    },
    // 添加
    addApiF() {
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      addHoneyDeviceGroup({
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        applyId: WORK_APPLY.id, //物联应用id
        name: this.groupnameVal, //分组名
        level: this.InfoData.level + 1, //父分组level+1
        parentId: this.InfoData.uId, //父分组uId
        split: this.InfoData.split, //父分组splite
      }).then((res) => {
        if (this.ApiReply(res)) {
          this.$message({
            message: "创建成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getTreeDeviceF();
        }
      });
    },
    // 更新
    updateApiF() {
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));

      update({
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        applyId: WORK_APPLY.id, //物联应用id
        name: this.groupnameVal, //新分组名
        oldName: this.InfoData.name, //原分组名
        uId: this.InfoData.uId,
        split: this.InfoData.split.split("/").slice(0, -1).join("/"), //父分组splite
      }).then((res) => {
        if (this.ApiReply(res)) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getTreeDeviceF();
        }
      });
    },
    updateGroupToGroupF() {
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      updateGroupToGroup({
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        applyId: WORK_APPLY.id, //物联应用id
        parentId: this.select.uId, //移动后的parentId
        oldParentId: this.InfoData.parentId, //原parentId
        newSort: this.select.childGroup.length + 1, //新排序
        sort: this.InfoData.sort, //原排序
        newLevel: this.select.level + 1, //新level
        oldSplit: this.InfoData.split, //原split
        newSplit: this.fatherDepartment, //新split  前端自行拼接
        id: this.InfoData.uId, //记录id
      }).then((res) => {
        if (this.ApiReply(res)) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getTreeDeviceF();
        }
      });
    },
    submint() {
      // 确定
      if (!this.groupnameVal) {
        this.$message({
          message: "分组名称不能为空",
          type: "warning",
        });
        return false;
      }

      switch (this.type) {
        case "add":
          this.addApiF();
          break;
        case "edit":
          this.updateApiF();
          break;
        case "move":
          if (JSON.stringify(this.select) == "{}") {
            this.$message({
              message: "请选择分组移动的位置",
              type: "warning",
            });
            return false;
          }
          this.updateGroupToGroupF();
          break;
        default:
          break;
      }
    },
    emitLeft(type, data) {
      // 左侧目录触发的方法
      console.log(type, data);
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      this.InfoData = {
        ...data,
      };
      this.type = type;
      switch (type) {
        case "add":
          this.dialogVisible = true;
          break;
        case "delate":
          // 删除
          this.$confirm("此操作将永久删除该分组, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.deletegroupF({
                enterpriseId: WORK_APPLY.enterpriseId, //企业id
                applyId: WORK_APPLY.id, //物联应用id
                uId: data.uId, //自身uId
                parentId: data.parentId, //parentId
                sort: data.sort, //排序
              });
            })
            .catch(() => {});
          break;
        case "edit":
          this.dialogVisible = true;
          this.groupnameVal = data.name;
          break;
        case "move":
          this.dialogVisible = true;
          this.groupSplit = data.split;
          this.groupnameVal = data.name;
          this.$nextTick(function () {
            this.$refs.MoveTreeList.filter();
          });
          break;
        default:
          break;
      }
    },
    deletegroupF(data) {
      deletegroup(data).then((res) => {
        if (this.ApiReply(res)) {
          this.$message({
            message: "已删除",
            type: "success",
          });
          this.getTreeDeviceF();
        }
      });
    },
    dialogclose() {
      // 关闭弹窗

      this.select = {};

      this.$refs.MoveTreeList.setCheckedKeys([], false);

      this.selectDepartmentS = false;
      this.fatherDepartment = "";
    },
    activeNodeF(data) {
      this.node = {
        ...data,
      };
      this.currentPage = 1;
      this.getDeviceF();
    },

    getTreeDeviceF() {
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      let data = {
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        applyId: WORK_APPLY.id, //物联应用id
      };
      tree(data).then((res) => {
        if (this.ApiReply(res)) {
          let treeData = res.data.data;
          this.node = {
            ...treeData[0],
          };

          this.treeData = [...treeData];
          this.getDeviceF();
        }
      });
    },
    getDeviceF() {
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      let data = {
        page: this.currentPage,
        limit: this.pageSize,
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        applyId: WORK_APPLY.id, //物联应用id
        groupId: this.node.uId, //设备分组uId
        deviceName: this.deviceName, //设备名称
        deviceType: this.deviceType, //0自有设备   1关联设备
      };
      treeDevice(data).then((res) => {
        if (this.ApiReply(res)) {
          this.deviceArr = [...res.data.data.tdata];
        }
      });
    },
    foldF() {
      this.foldFlag = !this.foldFlag;
      if (this.foldFlag) {
        this.foldStyle.width = "0";
        this.foldStyle.padding = "0";
      } else {
        this.foldStyle.width = "248px";
        this.foldStyle.padding = "16px";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select {
  position: relative;
  width: 367px;
}
.ops {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 8px 8px;
  padding-top: 20px;
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  height: 320px;
  // height: 180px;
  overflow: auto;
  top: 32px;
  background: #fff;
}
.page {
  display: flex;
  height: 64px;
  justify-content: center;
  align-items: center;
}
.equipment {
  height: 100%;
  width: 100%;
  .main {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  // 左侧目录
  .navList {
    background: #fff;
    width: 248px;
    height: 100%;
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(26, 20, 61, 0.1);
    transition: all 0.5s ease;
    overflow: hidden;
    transition: all 0.5s ease;
    &:hover {
      overflow: scroll;
      overflow-x: hidden;
    }
  }
  .listBox {
    border-radius: 5px;
    background: #fff;
    height: 100%;
    // box-shadow: 0 4px 6px 0 rgba(26, 20, 61, 0.1);
    box-shadow: 0 0 5px rgba(31, 35, 41, 0.1);
    padding: 24px 44px;
    min-width: 890px;
    .Head {
      display: flex;
      justify-content: space-between;
    }
    .head {
      height: 24px;
      line-height: 24px;
      padding-left: 20px;
      color: #1f2329;
      font-size: 16px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        border-left: 4px solid $main_color;
        height: 16px;
      }
      p {
        text-align: left;
      }
    }
    .option {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 15px;
      > div {
        display: flex;
        align-items: center;
      }
      .showType {
        color: $main_color;
        cursor: pointer;
        font-size: 13px;
      }
      .newadd {
        border: 0;
        font-size: 14px;

        .jiahao {
          font-size: 16px;
          margin-left: 9px;
          font-weight: 700;
          color: #56d79f;
        }
      }
      .line {
        width: 2px;
        height: 14px;
        background-color: #dedede;
      }
    }
  }
  // 右侧内容
  .details {
    position: relative;
    flex: 1;
    padding-left: 1px;
    overflow: auto;
    .on_off {
      position: absolute;
      top: 22px;
      left: 10px;
      cursor: pointer;
      i {
        font-size: 25px;
        color: #e8e8eb;
      }
      i:hover {
        color: #13cc74;
      }
    }
  }
}
</style>
<style lang="scss">
.EquipmentShowType {
  p.active {
    color: #56d79f;
  }
}
</style>
