<!--
 * @Description: 添加看板-表单设计-设置设备
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2020-10-10 14:34:13
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-11 16:20:52
-->

<template>
  <div id="SetText" class="SetText">
    <div v-if="selectS" class="select">
      <i class="el-icon-close close" @click="getDataF(0)"></i>
      <div class="title">选择设备</div>
      <select-box ref="selectBox"> </select-box>
      <div class="footer">
        <el-button @click="getDataF(0)" class="cancelcancel" size="small"
          >取 消</el-button
        >
        <el-button @click="getDataF(1)" type="primary" size="small"
          >确 定</el-button
        >
      </div>
    </div>
    <div class="model" v-show="selectS"></div>
  </div>
</template>

<script>
import selectBox from "./SelectDevice";
export default {
  name: "SetText",
  components: { selectBox },
  props: {},
  data() {
    return {
      selectS: false,
    };
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {},
  methods: {
    showSelectF() {
      this.selectS = true;
    },
    getDataF(type) {
      if (type == 0) {
        this.selectS = false;
      } else {
        let data = this.$refs.selectBox.selectDevice;
        if (JSON.stringify(data) != "{}") {
          this.selectS = false;
          this.$emit("select", data);
        } else {
          this.$message({
            message: "请选择设备",
            type: "error",
            duration: "2000",
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.model {
  position: fixed;
  @extend .model;
}
.select {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  z-index: 999;
  padding: 0 28px;
  border-radius: 10px;
  .close {
    font-size: 24px;
    color: #909399;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    z-index: 9;
    &:hover {
      color: $main_color;
    }
  }
  .title {
    padding: 28px;
    padding-bottom: 24px;
    padding-left: 20px;
    position: relative;
    text-align: left;
    &::after {
      content: "";
      position: absolute;
      top: 28px;

      margin: auto;
      left: 0;
      border-left: 4px solid #56d79f;
      height: 16px;
    }
  }
  .footer {
    padding: 28px 0;
    text-align: right;
  }
}
.SetText {
  .header {
    @extend .title_green;
    margin-bottom: 16px;
    font-family: PingFang SC, Microsoft YaHei;
    font-size: 18px;
    line-height: 28px;
    color: #1f2329;
    font-weight: 500;
  }
  .tip {
    margin-bottom: 32px;
    font-family: PingFang SC, Microsoft YaHei;
    font-size: 14px;
    line-height: 20px;
    color: #1f2329;
  }
  .seting {
    .input {
      min-height: 32px;
      line-height: 32px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      cursor: pointer;
      text-indent: 10px;
      font-size: 14px;
      color: #8f959e;
      @extend .oneline;
    }
    .input.error {
      border-color: #f54a45;
    }
    > div {
      margin-bottom: 32px;
      .domDiv {
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }
    }

    p.title {
      display: block;
      margin-bottom: 8px;
      color: #1f2329;
      font-family: PingFang SC, Microsoft YaHei;
      font-size: 14px;
      line-height: 20px;
      @extend .right_tip;
      > i {
        color: #f54a45;
        font-style: normal;
      }
    }
  }
}
</style>
<style lang="scss">
#SetText {
  .errorTip {
    color: #f54a45;
    font-family: PingFang SC, Microsoft YaHei;
    font-size: 14px;
    line-height: 20px;
  }
  .error {
    .el-input__inner {
      border-color: #f54a45;
    }
  }
}
</style>
