<!--
 * @Description: 移动设备
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2022-02-20 18:34:41
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-21 09:37:16
-->
<template>
  <div class="EquipmentMoveComponents">
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="425px"
      @close="dialogclose()"
    >
      <div class="dialogtitle" slot="title" v-text="'移动设备'"></div>
      <!-- main -->
      <div class="item">
        <p style="margin-bottom: 10px">设备名称:</p>
        <div>
          <el-input
            size="small"
            :disabled="true"
            v-model="info.deviceName"
          ></el-input>
        </div>
      </div>
      <div class="item" style="margin-top: 10px">
        <p style="margin-bottom: 10px">移动到</p>
        <div
          :class="selectDepartmentS ? 'select on' : 'select off'"
          @click.stop="selectDepartmentS = !selectDepartmentS"
        >
          <el-input
            v-model="fatherDepartment"
            placeholder="请输入内容"
            :readonly="true"
            size="small"
          >
            <i
              slot="suffix"
              style="transform-origin: center"
              :style="
                selectDepartmentS
                  ? 'cursor:pointer;padding-right:3px;transform: rotate(180deg)'
                  : 'cursor:pointer;padding-right:3px;transform: rotate(0)'
              "
              class="el-input__icon el-icon-caret-bottom"
            ></i>
          </el-input>
          <transition name="el-fade-in-linear">
            <div class="ops" @click.stop v-show="selectDepartmentS">
              <el-scrollbar style="height: 100%">
                <el-tree
                  :filter-node-method="filterNode"
                  auto-expand-parent
                  :data="treeData"
                  :default-expand-all="true"
                  show-checkbox
                  node-key="uId"
                  ref="MoveTreeList"
                  highlight-current
                  :check-strictly="true"
                  @check="checkChangeF"
                  :props="{ children: 'childGroup', label: 'name' }"
                  :check-on-click-node="true"
                ></el-tree>
              </el-scrollbar>
            </div>
          </transition>
        </div>
      </div>
      <!-- main end -->

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogclose()" size="small" class="cancelcancel"
          >取 消</el-button
        >
        <el-button type="primary" @click="submint()" size="small"
          >完 成</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateHoneyDeviceGroup } from "@/api/equipment.js";
export default {
  name: "EquipmentMoveComponents",
  components: {},
  props: {
    show: {},
    info: {},
    treeData: {},
    groupSplit: {},
  },
  data() {
    return {
      dialogVisible: false,
      select: {},
      selectDepartmentS: false,
      fatherDepartment: "",
    };
  },

  computed: {},
  //监听执行
  watch: {
    show() {
      this.dialogVisible = this.show;
      if (this.show) {
        console.log("move", this.info);
      }
    },
  },
  mounted() {},
  methods: {
    filterNode(val, data) {
      // 过滤
      return data.split.indexOf(this.groupSplit) !== 0;
    },
    submint() {
      if (JSON.stringify(this.select) != "{}") {
        console.log(this.select);
        let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
        updateHoneyDeviceGroup({
          enterpriseId: WORK_APPLY.enterpriseId, //企业id
          applyId: WORK_APPLY.id, //物联应用id
          id: this.info.id, //记录id
          groupId: this.select.uId, //移动后的分组id
        }).then((res) => {
          if (this.ApiReply(res)) {
            this.$message({
              message: "已移动",
              type: "success",
            });
            this.$emit("close", 1);
          }
        });
      } else {
        this.$message({
          type: "warning",
          message: "请选择设备要移动到的分组",
        });
      }
    },
    dialogclose() {
      this.select = {};
      this.$refs.MoveTreeList.setCheckedKeys([], false);
      this.selectDepartmentS = false;
      this.fatherDepartment = "";
      this.$emit("close");
    },
    checkChangeF(data) {
      this.select = data;
      this.fatherDepartment = this.select.split;
      this.$refs.MoveTreeList.setCheckedKeys([data.uId], false);
      this.selectDepartmentS = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.select {
  position: relative;
  width: 367px;
}
.ops {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 8px 8px;
  padding-top: 20px;
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  height: 320px;
  // height: 180px;
  overflow: auto;
  top: 32px;
  background: #fff;
}
.EquipmentMoveComponents {
}
</style>
