<!--
 * @Description: 选择设备
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-11 14:36:49
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-17 14:41:37
-->
<template>
  <div class="SelectDevice">
    <!-- 选择设备分组 -->
    <div>
      <div class="title">设备分组</div>
      <tree
        height="360px"
        treeListStyle="padding: 0 16px;"
        :data="GroupDevice"
        :defaultProps="{ children: 'children', label: 'name' }"
        @emitF="GroupDeviceEmitF"
      />
    </div>
    <!-- 选择设备分组 end-->
    <!-- 选择设备 -->
    <div>
      <div class="title">设备名称</div>
      <selectOne
        ref="selectOne"
        @emitF="DeviceEmitF"
        :load="DeviceLoad"
        :list="Device"
        imgType="circle"
        height="360px"
      />
    </div>
    <!-- 选择设备 end -->
  </div>
</template>

<script>
import tree from "@/components/public/SetFormSelectTreeItemComponents";
import selectOne from "@/components/public/SetFormSelectSelectItemOneScrollComponents";
import { queryGroupDevice, queryDevice } from "@/api/basics.js";
export default {
  name: "SelectDevice",
  components: { tree, selectOne },
  data() {
    return {
      GroupDevice: [], //设备分组
      GroupDeviceActiveData: {}, //选中的设备分组
      list1loading: false, //设备的loading
      Device: [], //设备
      filterText1: "",
      page: 1,
      DeviceLoad: true, //设备列表是否加载完 true 没加载完 false 加载完
      selectDevice: {}, //选中的设备
    };
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {
    this.queryGroupDevice(); //获取设备分组
  },
  methods: {
    // 选择设备分组返回数据
    GroupDeviceEmitF(data) {
      this.GroupDeviceActiveData = data;
      this.list1loading = true;
      this.DeviceLoad = true;
      // // 初始化
      // this.$refs.deviceList.refresh(); //取消设备列表选中
      this.page = 1;
      this.Device = [];
      this.queryDeviceList(data);
    },
    // 设备列表返回方法
    DeviceEmitF(type, data) {
      if (type == "load") {
        // 加载
        this.list1loading = true;
        this.page++;
        this.queryDeviceList(this.GroupDeviceActiveData);
      } else if (type == "select") {
        this.list1loading = true;
        this.filterText1 = data;
        this.page = 1;
        this.Device = [];
        this.DeviceLoad = true;
        this.queryDeviceList(this.GroupDeviceActiveData);
      } else if (type == "pull") {
        this.selectDevice = data;
      }
    },
    // 根据设备分组获取设备数据
    queryDeviceList(data) {
      let apidata = {
        deviceType: 0,
        enterpriseId: this.$store.state.systeam.enterpriseId,
        page: this.page,
        limit: 10,
        deviceName: this.filterText1,
        groupId: data.id,
        parentId: data.parentId,
        adminStatus: 1,
        isDelete: 0,
        menuId: 4,
      };

      queryDevice(apidata).then((res) => {
        if (this.ApiReply(res)) {
          if (res.data.data.page.totalPage == this.page) {
            // 加载完了
            this.DeviceLoad = false;
          }
          this.Device = this.Device.concat(res.data.data.tdata.date);
        }
        this.$refs.selectOne.loadend(); //将组件状态修改
        this.list1loading = false;
      });
    },
    // 获取设备分组
    queryGroupDevice() {
      queryGroupDevice({
        enterpriseId: this.$store.state.systeam.enterpriseId,
        deviceType: 0,
      }).then((res) => {
        if (this.ApiReply(res)) {
          this.GroupDevice = res.data.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.SelectDevice {
  display: flex;
  border-top: 1px solid #e4e9ee;
  border-bottom: 1px solid #e4e9ee;
  > div {
    width: 250px;
    height: 420px;
    border-right: 1px solid #e4e9ee;
    .title {
      height: 62px;
      line-height: 62px;
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #3b3b3b;
    }
  }
  > div:nth-child(1) {
    border-left: 1px solid #e4e9ee;
  }
}
</style>
