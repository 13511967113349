var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"EquipmentContentCardComponents"},[_c('div',{staticClass:"listBox"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.clickF(item)}}},[_c('div',{staticClass:"top_box",style:(_vm.deviceInfoObj[item.deviceId]
            ? _vm.changeBgColorTop(_vm.deviceInfoObj[item.deviceId].bgcolor)
            : '')},[_c('div',{staticClass:"iconandinfo"},[_c('div',{staticClass:"showicon_box"},[_c('span',{staticClass:"imgBox"}),_c('i',{class:_vm.deviceInfoObj[item.deviceId]
                  ? 'iconfont ' + _vm.deviceInfoObj[item.deviceId].imgUrl
                  : 'iconfont ql-iconfengshan',style:(_vm.deviceInfoObj[item.deviceId]
                  ? 'color:' + _vm.deviceInfoObj[item.deviceId].bgcolor
                  : 'color: rgb(24, 200, 115)')})])]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.deviceName,"placement":"top","disabled":item.deviceName.length < 12}},[_c('div',{staticClass:"title_name"},[_vm._v(" "+_vm._s(item.deviceName)+" "),(
                _vm.deviceInfoObj[item.deviceId] &&
                _vm.deviceInfoObj[item.deviceId].deviceName != item.name
              )?_c('span',[_vm._v("（"+_vm._s(_vm.deviceInfoObj[item.deviceId].deviceName)+"）")]):_vm._e()])])],1),_c('div',{staticClass:"circle_box",style:(_vm.deviceInfoObj[item.deviceId]
            ? _vm.changeBgColorUnder(_vm.deviceInfoObj[item.deviceId].bgcolor)
            : '')},[_c('div',{staticClass:"operate"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"移动","placement":"top"}},[_c('i',{staticClass:"move iconfont ql-iconyidong",on:{"click":function($event){$event.stopPropagation();return _vm.movedevice(item)}}})]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"删除","placement":"top"}},[_c('i',{staticClass:"delete iconfont ql-iconshanchu1",on:{"click":function($event){$event.stopPropagation();return _vm.delDevice(item)}}})])],1)])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }