<!--
 * @Description: 选择一个-滚动加载
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-03-17 19:56:51
 * @LastEditors: 支磊
 * @LastEditTime: 2021-11-23 09:43:52
-->
<template>
  <div class="SetFormSelectSelectItemOneComponents" :style="'height:' + height">
    <div class="select">
      <el-input
        placeholder="输入关键字进行过滤"
        v-model="filterText"
        size="small"
        @input="inputF"
        :disabled="filterText == '' && list.length == 0"
      >
      </el-input>
    </div>
    <div v-show="list.length == 0" class="notdata">
      <div>
        <!-- <img style="width: 96px" src="/img/notData.png" alt="" /> -->
        <p style="color: #909399; font-size: 14px; text-align: center">
          暂无数据
        </p>
      </div>
    </div>
    <div class="list" ref="listBox" v-show="list.length != 0">
      <vue-scroll :ops="ops" @handle-scroll="handleScroll">
        <div ref="list">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              @change="radioF(item)"
              v-for="(item, index) in list"
              :key="index"
              :label="JSON.stringify(item)"
            >
              <div class="item">
                <div
                  class="img"
                  v-if="imgType == 'circle'"
                  :style="'color:' + item.bgcolor"
                >
                  <span
                    class="imgBox"
                    :style="{
                      background: item.bgcolor,
                      opacity: 0.2,
                    }"
                  ></span>
                  <span
                    :class="'iconfont ' + item.imgUrl"
                    :style="
                      ('font-size:12px;background-color:' + item.bgcolor)
                        | changeBgColor
                    "
                  ></span>
                </div>
                <div class="img2" v-if="imgType == 'icon'">
                  <i :class="'iconfont ' + item.iconId"></i>
                  <svg-icon
                    :icon-class="item.bgcolor"
                    class="svgicon"
                  ></svg-icon>
                </div>
                <div class="text">
                  <ellipsis :contentText="item[name]"></ellipsis>
                </div>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
import ellipsis from "@/components/public/ellipsis2";
export default {
  name: "SetFormSelectSelectItemOneComponents",
  components: { ellipsis },
  filters: {
    changeBgColor(color) {
      let colorval = "";
      switch (color) {
        case "#56d79f":
          colorval = "greenbg";
          break;
        case "#00BDFB":
          colorval = "bluebg";
          break;
        case "#C24BFF":
          colorval = "purplebg";
          break;
        case "#FF9A16":
          colorval = "yellowbg";
          break;
        case "#FF7D7D":
          colorval = "redbg";
          break;

        default:
          colorval = "greenbg";
      }
      return colorval;
    },
  },
  props: {
    imgType: {
      type: String,
    },
    name: {
      type: String,
      default: "deviceName",
    },
    height: {
      //高度
      type: String,
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    load: {
      type: Boolean,
    },
  },
  data() {
    return {
      Height: 0,
      ulHeight: 0,
      scrollTop: 0,
      ops: {
        //配置
        wheelScrollDuration: 0,
        number: 1,
        wheelDirectionReverse: true,
        bar: {
          background: "#cecece",
        },
        scrollPanel: {
          scrollingX: false,
        },
      },
      filterText: "",
      checkList: [],
      style: 0,
      timeF: null,
    };
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {},
  methods: {
    inputF() {
      clearTimeout(this.timeF);
      this.timeF = setTimeout(() => {
        this.$emit("emitF", "select", this.filterText);
      }, 700);
    },
    loadend() {
      // 加载调用完接口触发
      this.style = 0;
    },
    handleScroll(vertical, horizontal, nativeEvent) {
      if (this.load) {
        this.scrollTop = vertical.scrollTop;
        this.Height = this.$refs.listBox.offsetHeight;
        this.ulHeight = this.$refs.list.offsetHeight; //已经看了的高度

        if (this.scrollTop + 10 + this.Height >= this.ulHeight) {
          // 如果快滚动到底部
          if (this.style == 0) {
            this.style = 1;
            this.$emit("emitF", "load");
          }
        }
      }
    },
    // 单选
    radioF(item) {
      this.checkList = [];
      this.checkList.push(JSON.stringify(item));
      this.$emit("emitF", "pull", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.SetFormSelectSelectItemOneComponents {
  /deep/.__bar-is-vertical {
    opacity: 1 !important;
  }
  height: 100%;
  .select {
    padding: 0 16px;
    margin-bottom: 10px;
  }
  .check {
    height: 34px;
    line-height: 34px;
  }
  .notdata {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list,
  .notdata {
    padding: 0 16px;
    height: calc(100% - 52px);
    overflow: auto;
    /deep/.el-checkbox {
      display: flex;
      align-items: center;
      margin-right: 0px;
    }
    /deep/.el-checkbox__label {
      flex: 1;
      padding-left: 0;
      @extend .oneline;
    }
    .item {
      width: 100%;
      padding: 0 6px;
      height: 34px;
      line-height: 34px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #3b3b3b;
      display: flex;
      align-items: center;
      > .text {
        flex: 1;
        @extend .oneline;
      }
      > .img2 {
        margin-right: 5px;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 19px;
        height: 19px;
        overflow: hidden;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-59%, -48%);
          font-size: 10px;
          color: #fff;
        }
        .svgicon {
          width: 40px;
          height: 40px;
        }
      }
      > .img {
        margin-right: 5px;
        display: inline-block;
        color: $main_color;
        position: relative;
        span {
          width: 20px;
          height: 20px;
          display: inline-block;
          line-height: 20px;
          border-radius: 50%;
          text-align: center;
        }
        span.imgBox {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
