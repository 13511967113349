<!--
 * @Description: 导入设备
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2022-02-17 09:28:32
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-18 10:03:25
-->
<template>
  <div class="EquipmentImportDeviceComponents">
    <div class="model"></div>
    <div class="form">
      <span class="close el-icon el-icon-close" @click="close"></span>
      <div class="title">导入设备</div>

      <div class="item">
        <div>
          <div class="lable"><span class="red">*</span> 选择设备：</div>
          <div @click="importF" style="cursor: pointer">
            <el-input
              :readonly="true"
              size="small"
              v-model="deviceName"
              placeholder="请选择要导入的设备"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="item">
        <div>
          <div class="lable"><span class="red">*</span> 设备类型：</div>
          <div style="cursor: pointer; text-align: left; padding-top: 10px">
            <el-radio-group v-model="deviceType">
              <el-radio :label="0">自有</el-radio>
              <el-radio :label="1">关联</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="item">
        <div>
          <div class="lable"><span class="red">*</span> 绑定设备：</div>
          <div style="cursor: pointer">
            <el-select v-model="value" placeholder="请选择" size="small">
              <el-option
                v-for="(item, index) in deviceOptions"
                :key="index"
                :label="item.name"
                :value="
                  deviceType == 1
                    ? item.deviceId + ',' + item.otherSiteId
                    : item.id
                "
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="foot">
        <el-button size="small" @click="close" class="cancelcancel"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="submintF"
          >完 成</el-button
        >
      </div>
    </div>
    <!-- 选择设备 -->
    <selectDevice ref="selectDevice" @select="selectDeviceF" />
    <!-- 选择设备 end -->
  </div>
</template>

<script>
import selectDevice from "./SelectDeviceDialog/SelectDeviceDialog";
import {
  getRelationHoneyDevice,
  getMyHoneyDevice,
  addHoneyDevice,
} from "@/api/equipment.js";
export default {
  name: "EquipmentImportDeviceComponents",
  components: { selectDevice },
  props: {
    node: {},
  },
  data() {
    return {
      value: "",
      deviceName: "", //设备名称
      deviceOptions: [],
      deviceInfo: {},
      deviceType: 0,
    };
  },

  computed: {},
  //监听执行
  watch: {
    deviceType() {
      this.value = "";
      if (this.deviceType == 0) {
        this.getMyHoneyDeviceF();
      } else {
        this.getRelationHoneyDeviceF();
      }
    },
  },
  mounted() {
    if (this.deviceType == 0) {
      this.getMyHoneyDeviceF();
    } else {
      this.getRelationHoneyDeviceF();
    }
  },
  methods: {
    getRelationHoneyDeviceF() {
      // 获取绑定设备
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      let data = {
        applyId: WORK_APPLY.id,
        enterpriseId: WORK_APPLY.enterpriseId,
      };
      getRelationHoneyDevice(data).then((res) => {
        if (this.ApiReply(res)) {
          this.deviceOptions = [...res.data.data];
        }
      });
    },
    getMyHoneyDeviceF() {
      // 获取自有设备
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      let data = {
        applyId: WORK_APPLY.id,
        enterpriseId: WORK_APPLY.enterpriseId,
      };
      getMyHoneyDevice(data).then((res) => {
        if (this.ApiReply(res)) {
          this.deviceOptions = [...res.data.data];
        }
      });
    },
    importF() {
      // 导入设备
      this.$refs.selectDevice.showSelectF();
    },
    close() {
      this.$emit("close");
    },
    submintF() {
      if (!this.deviceName) {
        this.$message({
          message: "请选择设备",
          type: "error",
        });
        return false;
      }
      if (!this.value) {
        this.$message({
          message: "请选择绑定设备",
          type: "error",
        });
        return false;
      }

      console.log(this.value, this.deviceInfo);
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      let data = {
        applyId: WORK_APPLY.id,
        enterpriseId: WORK_APPLY.enterpriseId,
        groupId: this.node.uId, //分组id
        siteId: this.deviceType == 0 ? "0" : this.value.split(",")[1], //霍尼组织id    自有设备传空串，关联设备取值otherSiteId
        qDeviceId: this.deviceInfo.id, //轻能设备id
        hDeviceId: this.deviceType == 0 ? this.value : this.value.split(",")[0], //霍尼韦尔设备id
        deviceType: this.deviceType, //类型  0自有设备   1关联设备
        imgUrl: "", //图片  多个逗号拼接
      };
      addHoneyDevice(data).then((res) => {
        if (this.ApiReply(res)) {
          this.$message({
            message: "设备已绑定",
            type: "success",
          });
          this.$emit("close", 1);
        }
      });
    },
    selectDeviceF(data) {
      // 选择的设备
      this.deviceInfo = {
        ...data,
      };
      this.deviceName = data.deviceName;
    },
  },
};
</script>
<style lang="scss" scoped>
.red {
  color: red;
}
.EquipmentImportDeviceComponents {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .model {
    position: fixed;
    @extend .model;
  }
  .form {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 458px;
    // height: 588px;
    background: #ffffff;
    border-radius: 10px;
    padding: 28px;
    .close {
      color: #909399;
      font-size: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 9;
    }
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #232626;
      height: 16px;
      line-height: 16px;
      text-align: left;
      padding-left: 14px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 16px;
        width: 4px;
        height: 16px;
        background: #56d79f;
      }
    }
    .lable {
      text-align: left;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #232626;
      height: 34px;
      line-height: 34px;
    }
    .item {
      padding-top: 10px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      > div {
        flex: 1;
      }

      .el-select {
        width: 100%;
      }
    }
    .foot {
      text-align: right;
      padding-top: 20px;
    }
  }
}
</style>
