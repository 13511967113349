<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-09 14:20:02
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-20 18:32:40
-->
<template>
  <div class="EquipmentContentCardComponents">
    <div class="listBox">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="clickF(item)"
      >
        <div
          class="top_box"
          :style="
            deviceInfoObj[item.deviceId]
              ? changeBgColorTop(deviceInfoObj[item.deviceId].bgcolor)
              : ''
          "
        >
          <div class="iconandinfo">
            <div class="showicon_box">
              <span class="imgBox"></span>
              <i
                :class="
                  deviceInfoObj[item.deviceId]
                    ? 'iconfont ' + deviceInfoObj[item.deviceId].imgUrl
                    : 'iconfont ql-iconfengshan'
                "
                :style="
                  deviceInfoObj[item.deviceId]
                    ? 'color:' + deviceInfoObj[item.deviceId].bgcolor
                    : 'color: rgb(24, 200, 115)'
                "
              ></i>
            </div>
          </div>

          <el-tooltip
            class="item"
            effect="dark"
            :content="item.deviceName"
            placement="top"
            :disabled="item.deviceName.length < 12"
          >
            <div class="title_name">
              {{ item.deviceName }}
              <span
                v-if="
                  deviceInfoObj[item.deviceId] &&
                  deviceInfoObj[item.deviceId].deviceName != item.name
                "
                >（{{ deviceInfoObj[item.deviceId].deviceName }}）</span
              >
            </div>
          </el-tooltip>
        </div>
        <div
          class="circle_box"
          :style="
            deviceInfoObj[item.deviceId]
              ? changeBgColorUnder(deviceInfoObj[item.deviceId].bgcolor)
              : ''
          "
        >
          <div class="operate">
            <el-tooltip
              class="item"
              effect="dark"
              content="移动"
              placement="top"
            >
              <i
                class="move iconfont ql-iconyidong"
                @click.stop="movedevice(item)"
              ></i>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
            >
              <i
                class="delete iconfont ql-iconshanchu1"
                @click.stop="delDevice(item)"
              ></i>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EquipmentContentCardComponents",
  components: {},
  props: {
    list: {},
    deviceInfoObj: {},
  },
  data() {
    return {};
  },

  computed: {},
  //监听执行
  watch: {
    list() {},
  },
  mounted() {},
  methods: {
    changeBgColorTop(color) {
      let colorval = "";
      switch (color) {
        case "#18C873":
          colorval = "background:#E1FAEF";
          break;
        case "#00BDFB":
          colorval = "background:#DCF5FF";
          break;
        case "#C24BFF":
          colorval = "background:#F2E8FF";
          break;
        case "#FF9A16":
          colorval = "background:#FFF4DE";
          break;
        case "#FF7D7D":
          colorval = "background:#FFE9E9";
          break;

        default:
          colorval = "background:#E1FAEF";
      }
      return colorval;
    },
    changeBgColorUnder(color) {
      let colorval = "";
      switch (color) {
        case "#18C873":
          colorval = "background: linear-gradient(180deg, #BBF0DA, #E1FAEF);";
          break;
        case "#00BDFB":
          colorval = "background: linear-gradient(180deg, #BEECFF, #DDF5FF);";
          break;
        case "#C24BFF":
          colorval = "background: linear-gradient(180deg, #E6D5FE, #F2E8FF);";
          break;
        case "#FF9A16":
          colorval = "background: linear-gradient(180deg, #FFEBC3, #FFF4DE)";
          break;
        case "#FF7D7D":
          colorval = "background: linear-gradient(180deg, #FFD9D9, #FFE9E9);";
          break;

        default:
          colorval = "background: linear-gradient(180deg, #BBF0DA, #E1FAEF);";
      }
      return colorval;
    },
    clickF(data) {
      // 点击设备，进入设备详情
      this.$emit("path", data);
    },
    movedevice(item) {
      this.$emit("operation", "move", item);
    },
    delDevice(item) {
      this.$emit("operation", "delete", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.EquipmentContentCardComponents {
  height: calc(100% - 135px);
  overflow: auto;
  .listBox {
    display: flex;
    padding: 20px 5px;
    flex-wrap: wrap;
    > .item {
      position: relative;
      width: 222px;
      height: 230px;
      margin: 0 44px 44px 0;
      overflow: hidden;
      box-shadow: 0px 0px 11px 0px rgba(23, 25, 24, 0.13);
      border-radius: 22px 22px 20px 20px;
      text-align: left;
      transition: all 0.5s ease;
      cursor: pointer;
      &:hover {
        margin-top: -5px;
      }
      .title_name {
        padding: 0 10px 0 20px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #87898d;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .top_box {
        height: calc(100% - 66px);
        background: rgb(225, 250, 239);
        .iconandinfo {
          padding: 15px 20px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .showicon_box {
          position: relative;
          width: 55px;
          height: 55px;
          i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 28px;
          }
        }
        .imgBox {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          background-color: #ffffff;
          box-shadow: 0px 8px 13px 0px rgba(111, 111, 118, 0.14);
          .svgicon {
            width: 20px;
            height: 20px;
          }
        }
      }
      .circle_box {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 15px 0 20px;
        width: 100%;
        height: 66px;
        background: linear-gradient(rgb(187, 240, 218), rgb(225, 250, 239));
        .operate {
          padding: 5px 10px;
          background: rgba(0, 0, 0, 0.13);
          border-radius: 13px;
          .move {
            margin-right: 20px;
          }
          i {
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
