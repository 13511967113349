<!--
 * @Description: 表单设计-选择-树形态
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-03-16 11:20:19
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-13 09:48:58
-->
<template>
  <div class="SetFormSelectTreeItemComponents" :style="'height:' + height">
    <div class="select">
      <el-input
        placeholder="输入关键字进行过滤"
        v-model="filterText"
        size="small"
        :disabled="filterText == '' && data.length == 0"
      >
      </el-input>
    </div>
    <div v-show="data.length == 0" class="notdata">
      <div>
        <!-- <img style="width: 96px" src="/img/notData.png" alt="" /> -->
        <p style="color: #909399; font-size: 14px; text-align: center">
          暂无数据
        </p>
      </div>
    </div>
    <div class="treeList" :style="treeListStyle" v-show="data.length != 0">
      <el-tree
        :expand-on-click-node="false"
        :highlight-current="true"
        :indent="10"
        class="filter-tree"
        :data="data"
        :props="defaultProps"
        :filter-node-method="filterNode"
        ref="tree"
        @node-click="nodeclick"
      >
        <p class="custom-tree-node" slot-scope="{ node }">
          <ellipsis :contentText="node.label"></ellipsis>
        </p>
      </el-tree>
    </div>
  </div>
</template>

<script>
import ellipsis from "@/components/public/ellipsis2";
export default {
  name: "SetFormSelectTreeItemComponents",
  components: { ellipsis },
  props: {
    height: {
      //高度
      type: String,
    },
    treeListStyle: {
      //树样式
      type: String,
    },
    data: {
      //树数据
      type: Array,
      default: () => [],
    },
    defaultProps: {
      // el-tree控件配置
      type: Object,
      default: () => ({
        children: "children",
        label: "label",
      }),
    },
  },
  data() {
    return {
      filterText: "",
    };
  },

  computed: {},
  //监听执行
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  mounted() {},
  methods: {
    nodeclick(data) {
      // 点击节点
      this.$emit("emitF", data);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data[this.defaultProps.label].indexOf(value) !== -1;
    },
  },
};
</script>
<style lang="scss" scoped>
.SetFormSelectTreeItemComponents {
  height: 100%;
  .select {
    padding: 0 16px;
    margin-bottom: 10px;
  }
  .notdata {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notdata {
    height: calc(100% - 52px);
  }
  .treeList,
  .notdata {
    max-height: calc(100% - 52px);
    overflow: auto;
  }
  /deep/.el-input {
    width: 100% !important;
  }
  /deep/.custom-tree-node {
    @extend .oneline;
  }
  /deep/.el-tree-node__label {
    line-height: 34px;
  }
  /deep/.el-tree-node__content {
    height: 34px;
  }
  .custom-tree-node {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3b3b3b;
  }
}
</style>
